"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useAuth, useUser } from "@clerk/nextjs";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import Link from "modules/common/components/navigation/Link";
import { Google, Inbox, Logout, Settings } from "@mui/icons-material";
import DiscordIcon from "modules/common/components/Icons/DiscordIcon";
import { ShortUser } from "modules/common/model/models";
import { useNotification } from "modules/chat/hooks/useNotification";

interface UserAvatarProps {
  width?: number;
  height?: number;
  appUser: ShortUser;
}

type SocialMediaLinks = {
  [key in "google" | "discord"]?: string;
};

const SocialMediaIcon = ({ platform }: { platform: "google" | "discord" }) => {
  const theme = useTheme();
  const icons = {
    google: (
      <Google
        fontSize="small"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
    discord: (
      <DiscordIcon
        fontSize="small"
        sx={{ color: theme.palette.primary.contrastText }}
      />
    ),
  };
  return icons[platform];
};

export const UserAvatar = ({
  width = 42,
  height = 42,
  appUser,
}: UserAvatarProps) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const { signOut } = useAuth();
  const { user, isLoaded } = useUser();
  const theme = useTheme();
  const { latestNotification } = useNotification();

  useEffect(() => {
    if (open && anchorRef.current) {
      anchorRef.current.focus();
    }
  }, [open]);

  const handleToggle = useCallback(() => setOpen((prev) => !prev), []);
  const handleClose = useCallback((event: Event | React.SyntheticEvent) => {
    if (anchorRef.current?.contains(event.target as HTMLElement)) return;
    setOpen(false);
  }, []);
  const handleSignOut = useCallback(() => {
    setOpen(false);
    signOut();
  }, [signOut]);

  const handleListKeyDown = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexGrow: 1,
        padding: 1,
      }}
    >
      {isLoaded && (
        <Box
          ref={anchorRef}
          onClick={handleToggle}
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <Badge
            color="error"
            variant="dot"
            invisible={latestNotification?.status !== "unread"}
          >
            <Avatar
              sx={{
                bgcolor: "#fff",
                width,
                height,
                border: `2px solid ${theme.palette.primary.main}`,
              }}
              alt={user?.username || ""}
              src={appUser.preferredAvatar}
            />
          </Badge>
        </Box>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-end" ? "right top" : "right bottom",
            }}
          >
            <Paper
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    component={Link}
                    href={`/users/${user?.username}`}
                    onClick={handleClose}
                    divider
                    sx={{
                      cursor: "default",
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                  >
                    <ListItemIcon>
                      <Tooltip title="Early Adopter">
                        <i
                          className="ms ms-planeswalker"
                          style={{ fontSize: "16pt", color: "black" }}
                        />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText>
                      {user?.primaryEmailAddress?.emailAddress}
                    </ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href="/settings?tab=all"
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Settings
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    href={
                      latestNotification
                        ? `/inbox/${latestNotification.chatId}`
                        : "/inbox"
                    }
                    onClick={handleClose}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={latestNotification?.status !== "unread"}
                      >
                        <Inbox
                          fontSize="small"
                          sx={{ color: theme.palette.primary.contrastText }}
                        />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText>Inbox</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={handleSignOut}
                    sx={{ color: theme.palette.primary.contrastText }}
                  >
                    <ListItemIcon>
                      <Logout
                        fontSize="small"
                        sx={{ color: theme.palette.primary.contrastText }}
                      />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};
