import React from "react";
import dynamic from "next/dynamic";
import { Loader } from "modules/common/components/Loader";

const DynamicCardDetailsDialog = () => {
  const Dialog = React.useMemo(
    () =>
      dynamic(
        () =>
          import(
            "modules/common/components/CardDetailsDialog/CardDetailsDialog"
          ),
        {
          loading: () => <Loader />,
        }
      ),
    [
      /* list variables which should trigger a re-render here */
    ]
  );
  return <Dialog />;
};

export default DynamicCardDetailsDialog;
