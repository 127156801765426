"use client";

import React, { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  Alert,
  Avatar,
  Box,
  Collapse,
  IconButton,
  Snackbar,
} from "@mui/material";
import {
  markNotificationAsRead,
  markNotificationAsShown,
} from "modules/server/actions/notifications";
import { Notification } from "modules/chat/model/chat";
import { useAuth } from "@clerk/nextjs";
import Link from "modules/common/components/navigation/Link";
import { Close } from "@mui/icons-material";
import { truncateText } from "utils/utils";
import { useParams, usePathname } from "next/navigation";
import { useNotification } from "modules/chat/hooks/useNotification";

const NotificationComponent = () => {
  const { userId, isLoaded } = useAuth();
  const queryClient = useQueryClient();
  const { notifications } = useNotification();
  const pathname = usePathname();
  const params = useParams();

  const [openSnackbars, setOpenSnackbars] = useState<Record<string, boolean>>(
    {}
  );

  useEffect(() => {
    (async () => {
      if (pathname.includes("inbox")) {
        const notif = notifications.find((n) => n.chatId === params.chatId);
        if (notif) {
          if (!notif.shown) await markNotificationAsShown(notif.id);
          if (notif.status === "unread") await markNotificationAsRead(notif.id);
        }
      }
    })();
  }, [pathname, notifications, params.chatId]);

  useEffect(() => {
    const newSnackbars: Record<string, boolean> = {};
    notifications.forEach((notification) => {
      if (!notification.shown) {
        newSnackbars[notification.id] = true;
      }
    });
    if (Object.keys(newSnackbars).length > 0) {
      setOpenSnackbars((prev) => ({ ...prev, ...newSnackbars }));
    }
  }, [notifications]);

  const handleNotificationClick = async (notification: Notification) => {
    await markNotificationAsRead(notification.id);
    queryClient.invalidateQueries({ queryKey: ["notifications", userId] });
    setOpenSnackbars((prev) => ({ ...prev, [notification.id]: false }));
  };

  const handleSnackbarClose = async (
    event: React.SyntheticEvent | Event,
    reason: string,
    id: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    await markNotificationAsShown(id); // Mark notification as shown
    setOpenSnackbars((prev) => ({ ...prev, [id]: false }));
    queryClient.invalidateQueries({ queryKey: ["notifications", userId] });
  };

  return (
    <>
      {isLoaded &&
        notifications
          .filter((notification) => notification.status === "unread")
          .map((notification) => (
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              key={notification.id}
              open={openSnackbars[notification.id] ?? false}
              autoHideDuration={3000}
              onClose={(event, reason) =>
                handleSnackbarClose(event, reason, notification.id)
              }
              onClick={() => handleNotificationClick(notification)}
            >
              <Collapse in={openSnackbars[notification.id] ?? false}>
                <Link href={`/inbox/${notification.chatId}`}>
                  <Alert
                    variant="filled"
                    // severity="info"

                    sx={{ alignItems: "center", p: 1, bgcolor: "primary.main" }}
                    icon={
                      <Avatar
                        alt="Chat User Avatar"
                        src={notification.sender.preferredAvatar}
                        sx={{
                          width: 24,
                          height: 24,
                          bgcolor: "#fff",
                        }}
                      />
                    }
                  >
                    <Box component="span" sx={{ fontWeight: "bold", mr: 0.5 }}>
                      {notification.sender.username}:
                    </Box>
                    <Box component="span" sx={{ flexGrow: 1, color: "black" }}>
                      {truncateText(notification.message, 25)}
                    </Box>
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      sx={{ ml: 1, p: 0 }}
                      onClick={() => {
                        setOpenSnackbars((prev) => ({
                          ...prev,
                          [notification.id]: false,
                        }));
                      }}
                    >
                      <Close fontSize={"small"} />
                    </IconButton>
                  </Alert>
                </Link>
              </Collapse>
            </Snackbar>
          ))}
    </>
  );
};

export default NotificationComponent;
