"use client";

import { useRecoilState } from "recoil";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import React, { ReactNode, useEffect } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { useMediaQuery } from "@mui/material";
import { DrawerComponent } from "modules/layout/DrawerComponent";
import { MainComponent } from "modules/layout/Main";
import DynamicCardDetailsDialog from "modules/common/components/CardDetailsDialog/dynamicImports/DynamicCardDetailsDialog";
import Footer from "modules/common/components/Footer";

interface SidebarLayoutProps {
  children: ReactNode;
}

export const AppLayout = ({ children }: SidebarLayoutProps) => {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(sidebarOpenAtom);
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (mdDown) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [mdDown, setOpen]);

  return (
    <>
      {mdDown && <DrawerComponent />}
      <MainComponent open={open} mdUp={!mdDown}>
        {children}
      </MainComponent>
      <Footer />
      <DynamicCardDetailsDialog />
    </>
  );
};
