"use client";
import React, { useState } from "react";
import {
  AppBarProps,
  Box,
  Button,
  Collapse,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useRecoilState } from "recoil";
import { UserAvatar } from "modules/common/components/UserAvatar";
import { BRAND_NAME } from "utils/constants";
import Link from "modules/common/components/navigation/Link";
import { useAuth } from "@clerk/nextjs";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import CardSearchAutocomplete from "modules/common/components/NavCardSearch/CardSearchAutocomplete";
import { ShortUser } from "modules/common/model/models";
import { Menu } from "modules/common/components/navigation/Menu";
import { VersionTagRibbon } from "modules/common/components/VersionTagRibbon";

interface CustomAppBarProps extends AppBarProps {
  open: boolean;
  mdUp: boolean;
}

const CustomAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "mdUp",
})<CustomAppBarProps>(({ theme, open, mdUp }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open &&
    mdUp && {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}));

interface Props {
  appUser: ShortUser | null;
}

export const AppBarComponent = ({ appUser }: Props) => {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(sidebarOpenAtom);
  const [showSearch, setShowSearch] = useState(false);
  const { isSignedIn } = useAuth();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrawerToggle = () => setOpen((prevOpen) => !prevOpen);
  const handleSearchToggle = () =>
    setShowSearch((prevShowSearch) => !prevShowSearch);
  const handleSearchComplete = () => {
    if (mdDown) {
      setShowSearch(false);
    }
  };

  return (
    <>
      <CustomAppBar position="sticky" open={open} mdUp={mdUp}>
        <Toolbar
          sx={{
            width: "100%",
            justifyContent: mdUp ? "space-between" : "center",
            gap: 0.5,
            flexDirection: mdUp ? "row" : "column",
            alignItems: mdUp ? "center" : "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: { xs: "100%", md: "auto" },
              justifyContent: { xs: "space-between", md: "flex-start" },
            }}
          >
            <Typography
              variant="caption"
              fontSize={24}
              noWrap
              component="div"
              color="primary"
            >
              {BRAND_NAME}
            </Typography>
            {mdDown && (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  color="primary"
                  aria-label="toggle search"
                  onClick={handleSearchToggle}
                >
                  {showSearch ? <CloseIcon /> : <SearchIcon />}
                </IconButton>
                {isSignedIn && appUser && (
                  <UserAvatar width={24} height={24} appUser={appUser} />
                )}
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="end"
                >
                  {open ? (
                    theme.direction === "ltr" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )
                  ) : (
                    <MenuIcon />
                  )}
                </IconButton>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: mdUp ? "flex" : "none",
              justifyContent: "center",
              width: "100%",
              transition: "all 0.3s ease",
              paddingBottom: mdUp ? 0 : 1,
            }}
          >
            <Menu />
          </Box>
          {mdUp && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                minWidth: "fit-content",
                gap: 1,
              }}
            >
              {/* <IconButton
                color="primary"
                aria-label="toggle search"
                onClick={handleSearchToggle}
              >
                {showSearch ? <CloseIcon /> : <SearchIcon />}
              </IconButton>*/}
              {isSignedIn && appUser ? (
                <UserAvatar appUser={appUser} />
              ) : (
                <>
                  <Link href="/sign-in">
                    <Button variant="outlined">Sign In</Button>
                  </Link>
                  <Link href="/sign-up">
                    <Button variant="contained">Sign Up</Button>
                  </Link>
                </>
              )}
              <VersionTagRibbon />
            </Box>
          )}
        </Toolbar>
        <Collapse in={showSearch} timeout="auto">
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: "inset 0 0 16px rgba(0, 0, 0, 0.40)",
              zIndex: theme.zIndex.appBar + 1,
              padding: theme.spacing(1),
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CardSearchAutocomplete
              styles={{ width: "100%", maxWidth: 340 }}
              size="small"
              onSearchComplete={handleSearchComplete}
            />
          </Box>
        </Collapse>
      </CustomAppBar>
    </>
  );
};
