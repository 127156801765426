import React, { CSSProperties, ReactNode } from "react";
import { Box, CircularProgress } from "@mui/material";

interface LoaderProps {
  size?: number;
  style?: CSSProperties;
  children?: ReactNode;
}

export const Loader: React.FC<LoaderProps> = ({
  size = 50,
  style,
  children,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 300,
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
        ...style,
      }}
    >
      <CircularProgress size={size} />
      {children}
    </Box>
  );
};
