"use client";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import { theme } from "modules/common/components/ThemeRegistry/theme";
import { SnackbarProvider } from "notistack";
import { ConfirmDeleteModal } from "modules/common/components/ConfirmDeleteModal";

// This implementation is from emotion-js
// https://github.com/emotion-js/emotion/issues/2928#issuecomment-1319747902
export default function ThemeRegistry(props: { children: any }) {
  const { children } = props;

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        autoHideDuration={2500}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        maxSnack={3}
      >
        <CssBaseline />
        {children}
        <ConfirmDeleteModal />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
