import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "@mui/material/styles/useTheme";
import { useVersion } from "modules/version/useVersion";

export const VersionTagRibbon = () => {
  const query = useVersion();
  const theme = useTheme();

  return query.isSuccess ? (
    <Box
      sx={{
        // position: "fixed",
        height: "22px",
        bottom: 0,
        width: "inherit",
        bgcolor: theme.palette.background.default,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Typography variant={"caption"} color={"secondary"}>
        {query.data.tag}
      </Typography>
    </Box>
  ) : (
    <></>
  );
};
