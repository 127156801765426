"use client";

import React, { RefObject, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, useMediaQuery } from "@mui/material";
import {
  ArrowDropDown,
  Groups2 as Groups2Icon,
  Home as HomeIcon,
  MilitaryTech as MilitaryTechIcon,
  ReceiptLong as ReceiptLongIcon,
  Search,
  Store,
  Style as StyleIcon,
} from "@mui/icons-material";
import { useAuth } from "@clerk/nextjs";
import { usePathname } from "next/navigation";
import { activeButton } from "utils/utils";
import Link from "modules/common/components/navigation/Link";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";
import { useSetRecoilState } from "recoil";
import { DecksIcon } from "modules/common/components/Icons/DecksIcon";
import { CollectionIcon } from "modules/common/components/Icons/CollectionIcon";
import useTheme from "@mui/material/styles/useTheme";
import CardSearchAutocomplete from "modules/common/components/NavCardSearch/CardSearchAutocomplete";
import { DropdownMenu } from "modules/common/components/navigation/DropdownMenu";

const exploreMenuItems = [
  {
    href: "/sets",
    text: "Cards",
    icon: <StyleIcon sx={{ transform: "rotate(180deg)" }} />,
  },
  {
    href: "/decks",
    text: "Decks",
    icon: <Groups2Icon />,
  },
];

const tradeMenuItems = [
  {
    href: "/trade/lists",
    text: "Lists",
    icon: <ReceiptLongIcon />,
  },
  {
    href: "/trade/stores",
    text: "Stores",
    icon: <Search />,
  },
  {
    href: "/trade/your-stores",
    text: "Your Stores",
    icon: <Store />,
  },
];

const metagameMenuItems = [
  {
    href: "/metagame/modern",
    text: "Modern",
    icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/legacy",
    text: "Legacy",
    icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/standard",
    text: "Standard",
    icon: <MilitaryTechIcon />,
  },
  {
    href: "/metagame/pauper",
    text: "Pauper",
    icon: <MilitaryTechIcon />,
  },
];

export const Menu = () => {
  const setSidebarOpen = useSetRecoilState(sidebarOpenAtom);
  const { isSignedIn } = useAuth();
  const pathName = usePathname();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleRouteClick = () => {
    if (mdDown) {
      setSidebarOpen(false);
    }
  };

  const [exploreMenuOpen, setExploreMenuOpen] = useState(false);
  const exploreMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const [tradeMenuOpen, setTradeMenuOpen] = useState(false);
  const tradeMenuAnchorRef = useRef<HTMLButtonElement>(null);
  const [metagameMenuOpen, setMetagameMenuOpen] = useState(false);
  const metagameMenuAnchorRef = useRef<HTMLButtonElement>(null);

  const handleMenuToggle = (
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (
    event: Event | React.SyntheticEvent,
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>,
    menuAnchorRef: RefObject<HTMLButtonElement>
  ) => {
    handleRouteClick();
    if (
      menuAnchorRef.current &&
      menuAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setMenuOpen(false);
  };

  const handleListKeyDown = (
    event: React.KeyboardEvent,
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (event.key === "Tab" || event.key === "Escape") {
      event.preventDefault();
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    if (tradeMenuOpen && tradeMenuAnchorRef.current) {
      tradeMenuAnchorRef.current.focus();
    }
  }, [tradeMenuOpen]);

  useEffect(() => {
    if (exploreMenuOpen && exploreMenuAnchorRef.current) {
      exploreMenuAnchorRef.current.focus();
    }
  }, [exploreMenuOpen]);

  useEffect(() => {
    if (metagameMenuOpen && metagameMenuAnchorRef.current) {
      metagameMenuAnchorRef.current.focus();
    }
  }, [metagameMenuOpen]);

  return (
    <Box
      component="nav"
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
        color: "primary.main",
        textDecoration: "none",
      }}
    >
      <Link href="/">
        <Button
          onClick={handleRouteClick}
          startIcon={<HomeIcon />}
          variant={activeButton(pathName, "/") ? "contained" : "text"}
        >
          Home
        </Button>
      </Link>
      <Box>
        <Button
          ref={exploreMenuAnchorRef}
          onClick={() => handleMenuToggle(setExploreMenuOpen)}
          startIcon={<Groups2Icon />}
          endIcon={<ArrowDropDown />}
          sx={{ color: "primary.main" }}
        >
          Explore
        </Button>
        <DropdownMenu
          buttonRef={exploreMenuAnchorRef}
          isOpen={exploreMenuOpen}
          toggleMenu={() => handleMenuToggle(setExploreMenuOpen)}
          closeMenu={(event) =>
            handleMenuClose(event, setExploreMenuOpen, exploreMenuAnchorRef)
          }
          menuItems={exploreMenuItems}
          handleListKeyDown={(event) =>
            handleListKeyDown(event, setExploreMenuOpen)
          }
        />
      </Box>
      <Box>
        <Button
          ref={metagameMenuAnchorRef}
          onClick={() => handleMenuToggle(setMetagameMenuOpen)}
          startIcon={<MilitaryTechIcon />}
          endIcon={<ArrowDropDown />}
          sx={{ color: "primary.main" }}
        >
          Metagame
        </Button>
        <DropdownMenu
          buttonRef={metagameMenuAnchorRef}
          isOpen={metagameMenuOpen}
          toggleMenu={() => handleMenuToggle(setMetagameMenuOpen)}
          closeMenu={(event) =>
            handleMenuClose(event, setMetagameMenuOpen, metagameMenuAnchorRef)
          }
          menuItems={metagameMenuItems}
          handleListKeyDown={(event) =>
            handleListKeyDown(event, setMetagameMenuOpen)
          }
        />
      </Box>
      <Link href="/decks">
        <Button
          onClick={handleRouteClick}
          startIcon={<DecksIcon />}
          variant={activeButton(pathName, "/decks") ? "contained" : "text"}
        >
          Decks
        </Button>
      </Link>
      {isSignedIn && (
        <Link href="/collection">
          <Button
            onClick={handleRouteClick}
            startIcon={<CollectionIcon />}
            variant={
              activeButton(pathName, "/collection") ? "contained" : "text"
            }
          >
            Collection
          </Button>
        </Link>
      )}
      <Box>
        <Button
          ref={tradeMenuAnchorRef}
          onClick={() => handleMenuToggle(setTradeMenuOpen)}
          startIcon={<Groups2Icon />}
          endIcon={<ArrowDropDown />}
          sx={{ color: "primary.main" }}
        >
          Trade
        </Button>
        <DropdownMenu
          buttonRef={tradeMenuAnchorRef}
          isOpen={tradeMenuOpen}
          toggleMenu={() => handleMenuToggle(setTradeMenuOpen)}
          closeMenu={(event) =>
            handleMenuClose(event, setTradeMenuOpen, tradeMenuAnchorRef)
          }
          menuItems={tradeMenuItems}
          handleListKeyDown={(event) =>
            handleListKeyDown(event, setTradeMenuOpen)
          }
        />
      </Box>
      <Divider orientation="vertical" flexItem />
      {!mdDown && (
        <CardSearchAutocomplete
          styles={{ width: "340px" }}
          size="small"
          onSearchComplete={handleRouteClick}
        />
      )}
    </Box>
  );
};
