"use client";
import { useSetRecoilState } from "recoil";
import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import ListItemText from "@mui/material/ListItemText";
import { DecksIcon } from "modules/common/components/Icons/DecksIcon";
import { CollectionIcon } from "modules/common/components/Icons/CollectionIcon";
import { Collapse, Divider, useMediaQuery } from "@mui/material";
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Groups2 as Groups2Icon,
  ReceiptLong as ReceiptLongIcon,
  Search,
  Store,
  Style as StyleIcon,
  SwapHoriz as SwapHorizIcon,
} from "@mui/icons-material";
import { useAuth } from "@clerk/nextjs";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import { usePathname } from "next/navigation";
import { activeButton } from "utils/utils";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import Link from "modules/common/components/navigation/Link";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import { sidebarOpenAtom } from "modules/layout/recoil/atoms";

export const MobileMenu = () => {
  const setSidebarOpen = useSetRecoilState(sidebarOpenAtom);
  const [tradeOpen, setTradeOpen] = useState(true);
  const { isSignedIn } = useAuth();
  const lgDown = useMediaQuery((theme: any) => theme.breakpoints.down("lg"));
  const pathName = usePathname();

  const handleTradeClick = () => {
    setTradeOpen(!tradeOpen);
  };

  const handleRouteClick = () => {
    if (lgDown) {
      setSidebarOpen(false);
    }
  };

  return (
    <List
      component={"nav"}
      sx={{ color: "primary.main", textDecoration: "none" }}
    >
      <Link href={"/"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/")}
        >
          <ListItemIcon>
            <HomeIcon color={"info"} />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItemButton>
      </Link>
      <Link href={"/sets"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/sets")}
        >
          <ListItemIcon>
            <StyleIcon
              color={"info"}
              sx={{
                transform: "rotate(180deg)",
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Cards" />
        </ListItemButton>
      </Link>
      <Link href={"/metagame"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/metagame")}
        >
          <ListItemIcon>
            <MilitaryTechIcon color={"info"} fontSize={"medium"} />
          </ListItemIcon>
          <ListItemText primary="Metagame" />
        </ListItemButton>
      </Link>
      <Link href={"/decks"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/decks")}
        >
          <ListItemIcon>
            <DecksIcon color={"info"} />
          </ListItemIcon>
          <ListItemText primary="Decks" />
        </ListItemButton>
      </Link>
      {isSignedIn && (
        <Link href={"/collection"}>
          <ListItemButton
            onClick={handleRouteClick}
            selected={activeButton(pathName, "/collection")}
          >
            <ListItemIcon>
              <CollectionIcon color={"info"} />
            </ListItemIcon>
            <ListItemText primary="Collection" />
          </ListItemButton>
        </Link>
      )}
      {
        <>
          <ListItemButton
            onClick={handleTradeClick}
            sx={{
              color: "primary.main",
            }}
          >
            <ListItemIcon>
              <Groups2Icon color={"info"} />
            </ListItemIcon>
            <ListItemText primary="Trade" />
            {tradeOpen ? (
              <ExpandLessIcon color={"secondary"} />
            ) : (
              <ExpandMoreIcon color={"secondary"} />
            )}
          </ListItemButton>
          <Collapse in={tradeOpen} timeout={"auto"} unmountOnExit>
            <List
              sx={{
                pt: 0,
              }}
            >
              {isSignedIn && (
                <Link href={"/trade/lists"}>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={handleRouteClick}
                    selected={activeButton(pathName, "/trade/lists")}
                  >
                    <ListItemIcon>
                      <ReceiptLongIcon color={"info"} />
                    </ListItemIcon>
                    <ListItemText primary="Lists" />
                  </ListItemButton>
                </Link>
              )}
              <Link href={"/trade/stores"}>
                <ListItemButton
                  sx={{ pl: 4 }}
                  onClick={handleRouteClick}
                  selected={activeButton(pathName, "/trade/stores")}
                >
                  <ListItemIcon>
                    <Search color={"info"} />
                  </ListItemIcon>
                  <ListItemText primary="Stores" />
                </ListItemButton>
              </Link>
              {isSignedIn && (
                <Link href={"/trade/your-stores"}>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={handleRouteClick}
                    selected={activeButton(pathName, "/trade/your-stores")}
                  >
                    <ListItemIcon>
                      <Store color={"info"} />
                    </ListItemIcon>
                    <ListItemText primary="Your Stores" />
                  </ListItemButton>
                </Link>
              )}
            </List>
          </Collapse>
        </>
      }
      <Link href={"/swap-calculator"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/swap-calculator")}
        >
          <ListItemIcon>
            <SwapHorizIcon color={"info"} />
          </ListItemIcon>
          <ListItemText primary="Swap Calculator" />
        </ListItemButton>
      </Link>
      <Divider
        sx={{
          my: 1,
        }}
      />
      <Link href={"/about"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/about")}
        >
          <ListItemIcon>
            <HelpCenterIcon color={"info"} />
          </ListItemIcon>
          <ListItemText primary="About" />
        </ListItemButton>
      </Link>
      <Link href={"/privacy"}>
        <ListItemButton
          onClick={handleRouteClick}
          selected={activeButton(pathName, "/privacy")}
        >
          <ListItemIcon>
            <PrivacyTipIcon color={"info"} />
          </ListItemIcon>
          <ListItemText primary="Privacy" />
        </ListItemButton>
      </Link>
    </List>
  );
};
